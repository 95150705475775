body.download {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.download .container-download {
    max-width: 640px;
}

body.download h1 {
    font-size: 16px;
    font-weight: bold;
}

body.download h2 {
    font-size: 14px;
}

body.download p {
    font-size: 14px;
    margin: 0;
}

body.download table {
    font-size: 14px;
    width: calc(100% - 60px);
    background-color: #F3F4F5;
    margin: 30px;
}

body.download table tr {
    background-color: #E2E2E2;
    border: 20px solid #EAEAEA;
}

body.download table td,
body.download .table td {
    padding: 5px;
}

body.download .td-left {
    padding-left: 20px !important;
    max-width: 45%;
}

body.download .desktop-view-table {
    display: block;
}

body.download .mobile-view-table {
    display: none;
}

body.download .mobile-view-table td {
    padding-left: 20px !important;
    width: 100%;
    font-weight: normal;
}

body.download .mobile-view-table .td-left {
    padding: 1px !important;
    padding-left: 20px !important;
    width: 100%;
    font-weight: bold !important;
    background-color: #F3F4F5 !important;
}

body.download .ref-block {
    margin: 50px;
}

body.download .block-atas {
    margin-bottom: 20px;
}

body.download .block-bawah {
    margin-top: 20px;
}

body.download .table {
    background-color: #F3F4F5;
    overflow: hidden;
}

body.download .table-title {
    background-color: #EAEAEA;
    margin: 10px;
    vertical-align: middle;
    padding: 10px 30px;
    margin: 20px 30px 0 30px;
}

body.download .informasi {
    margin-bottom: 20px;
}

body.download .download-footer {
    text-align: center;
    color: #B1AEAE;
    font-size: 10px;
    margin-top: 30px;
}

body.download .download-footer p {
    font-size: 12px;
}

body.download .download-footer img {
    margin: 20px 0;
}

@media only screen and (max-width: 640px) {
    body.download .ref-block {
        margin: 50px 20px;
    }

    body.download .desktop-view-table {
        display: none;
    }

    body.download .mobile-view-table {
        display: block;
    }
}
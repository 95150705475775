/* to make sure the datepicker popup appears in front */
.react-datepicker-wrapper,
/* .react-datepicker__input-container,
.react-datepicker__input-container input, */
.react-datepicker-popper {
  z-index: 9999 !important;
  display: block !important;
  width: 100% !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  /* border-bottom-color: #3d3daa !important; */
  border-bottom: none;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
  border-top: none;
}

.react-datepicker__header {
  background-color: #3d3daa !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #fff !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #fff !important;
}

.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker__day, .react-datepicker__time-name {
  color: #000 !important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff !important;
  background-color: #3d3daa !important;
}

.react-datepicker__header__dropdown select {
  padding: 2px 1px !important;
  border-color: #3d3daa !important;
  border-radius: 3px !important;
}

.react-datepicker__header__dropdown select option:focus,
.react-datepicker__header__dropdown select option:checked,
.react-datepicker__header__dropdown select option[selected],
.react-datepicker__header__dropdown select:focus option:focus,
.react-datepicker__header__dropdown select:focus option:checked,
.react-datepicker__header__dropdown select:focus option[selected] {
  background: #3d3daa !important;
  color: #fff !important;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default !important;
  color: #ccc !important;
}
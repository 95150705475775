.bg-gray-w-radius {
    background-color: #f4f2f8;
    border-radius: 10px;
}

.v-middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn.disabled, .btn:disabled {
    background-color: #E5E5E5 !important;
    color: #999999 !important;
    border-color: #E5E5E5 !important;

}

.captcha {
    width: 100%;
    height: 100%;
    padding: 15px 0px 0px 0px;
}

.input-captcha {
    font-weight: bolder;
    /* letter-spacing: 0.375rem; */
    /* text-align: center; */
}

.lspace-2 {
    letter-spacing: 0.375rem;
}

.form-group {
    position: relative;
    margin-bottom: 5px;
    margin-top: 1rem;
}

.form-group .input-group-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.form-group .input-group-icon-right {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

select.form-control, .form-select {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
}

.flip-2 {
    transform: rotate(270deg);
}

/* #react-select-2-listbox {
    z-index: 99999999 !important;
    background-color: red;
} */

.ma-frame {
    width: 100%;
    padding: 1em;
}

.force-block {
    display: block !important;
    float: right !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.notification-block-dbb {
    color: red;
    font-size: 14px;
    background-color: #e9ecef;
    margin-top: 20px;
    padding: 12px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: justify;
}

.konfirmasi-detail {
    display: grid;
    grid-template-rows: 0fr 0fr 0fr 0fr;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    font-size: 0.875rem;
    text-align: left;
    max-height: fit-content;
}

.swal2-styled.swal2-cancel {
    background-color: #fff !important;
    border-color: rgb(0, 0, 143) !important;
    color: rgb(0, 0, 143) !important;
    border: 1px solid rgb(0, 0, 143) !important;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    background-image: unset !important;
}

.swal2-icon.swal2-info {
    border-color: rgb(0, 0, 143) !important;
    color: rgb(0, 0, 143) !important;
}

.logo-link {
    border: none;
    outline: none;
    background-color: transparent;
}

.dashboard-admin input.form-control,
.dashboard-admin select.form-control {
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0px;
}

.dashboard-admin .btn {
    border-radius: 0px;
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
}

.dashboard-admin .table>:not(caption)>*>* {
    padding: 0.675rem 0.675rem;
}

.dashboard-admin .table th {
    font-weight: 600;
}

.dashboard-login .btn,
.token-login .btn,
.token-app .btn {
    border-radius: 0px;
    padding-top: 0.675rem;
    padding-bottom: 0.675rem;
}

.token-app .upload-charge {
    max-width: 490px;
}

.token-app .table th,
.dashboard-admin .table th {
    background-color: #4976ba;
    color: #fff;
    border: unset;
}

.token-app .table td,
.dashboard-admin .table td {
    border-color: #a0a0a0;
}

.token-app .btn-primary,
.dashboard-admin .btn-primary {
    background-color: #4976ba;
    border-color: #4976ba;
}

.token-app .btn.btn-primary:hover,
.token-app .btn.btn-primary:active,
.token-app .btn.btn-primary:focus,
.token-app .btn.btn-primary:focus-visible,
.dashboard-admin .btn.btn-primary:hover,
.dashboard-admin .btn.btn-primary:active,
.dashboard-admin .btn.btn-primary:focus,
.dashboard-admin .btn.btn-primary:focus-visible {
    background-color: #3162ab;
    border-color: #3162ab;
}

.token-app .btn-secondary {
    background-color: #de6c8f;
    border-color: #de6c8f;
}

.token-app .btn.btn-secondary:hover,
.token-app .btn.btn-secondary:active,
.token-app .btn.btn-secondary:focus,
.token-app .btn.btn-secondary:focus-visible {
    background-color: #cc577b;
    border-color: #cc577b;
}

.token-app .dropdown .dropdown-item {
    color: #212529;
    font-weight: 400;
}

.card.dashboard-login,
.dashboard-login input.form-control,
.card.token-login,
.token-login input.form-control {
    border-radius: 0;
}

.token-app .dropdown-menu.show {
    border-radius: 0;
}

.token-app .dropdown-item.active,
.token-app .dropdown-item:active {
    background-color: #4976ba;
    color: #fff;
}
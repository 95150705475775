[class*=ic-] {
    aspect-ratio: 1;
}

.ic-location {
    width: 16px;
    height: 16px;
    background-image: url(../img/icons/ic-location.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-parcel {
    width: 16px;
    height: 16px;
    background-image: url(../img/icons/ic-parcel.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-clock {
    width: 16px;
    height: 16px;
    background-image: url(../img/icons/ic-clock.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-call {
    width: 16px;
    height: 16px;
    background-image: url(../img/icons/ic-call.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-email {
    width: 16px;
    height: 16px;
    background-image: url(../img/icons/ic-message.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-facebook {
    width: 22px;
    height: 22px;
    background-image: url(../img/icons/ic-facebook.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-linkedin {
    width: 22px;
    height: 22px;
    background-image: url(../img/icons/ic-linkedin.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-youtube {
    width: 22px;
    height: 22px;
    background-image: url(../img/icons/ic-youtube.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-ig {
    width: 22px;
    height: 22px;
    background-image: url(../img/icons/ic-ig.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-twitter {
    width: 22px;
    height: 22px;
    background-image: url(../img/icons/ic-twitter.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-back {
    width: 25px;
    height: 25px;
    background-image: url(../img/icons/ic-back.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-back-mobile {
    width: 32px;
    height: 32px;
    background-image: url(../img/icons/ic-back-mobile.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-out {
    width: 25px;
    height: 25px;
    background-image: url(../img/icons/ic-out.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-clock-blue {
    width: 20px;
    height: 20px;
    background-image: url(../img/icons/ic-clock-blue.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-refresh {
    width: 20px;
    height: 20px;
    background-image: url(../img/icons/ic-refresh.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-alert {
    height: 25px;
    background-image: url(../img/icons/ic-alert.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-info {
    width: 25px;
    height: 25px;
    background-image: url(../img/icons/ic-info.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.ic-menu-burger {
    width: 25px;
    height: 25px;
    background-image: url(../img/icons/ic-menu-burger.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}
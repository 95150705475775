@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

html, body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
}

.cardpaymentotp {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #000;
    line-height: 1.3;
}
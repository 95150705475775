#spinner .sl-spinner3 {
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
}

#spinner .sl-spinner3 .sl-loader {
    height: 20px !important;
    width: 20px !important;
    margin-top: -3px !important;
}

.ilb {
    display: inline-block !important;
}
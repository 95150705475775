/* iPad Pro */
@media screen and (min-width: 1024px) and (orientation: portrait) {

    .container.pembayaran-kartu,
    .container.redirect,
    .container.otp {
        min-height: calc(100vh - 98px - 74px - 40px - 376px);
    }
}

@media screen and (min-width: 768px) {

    .container.redirect,
    .container.otp {
        min-height: calc(100vh - 98px - 74px - 40px - 376px);
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .card.login-form .card-body {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .warning-box {
        text-align: center;
    }

    .login-page>.row>* {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .login-page>.row>div:first-child>p {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .login-page {
        height: fit-content;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .login-logo {
        height: 72px;
        width: auto;
        margin-bottom: 1.5rem;
    }

    .login-illustration {
        width: 100%;
        height: auto;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .login-form {
        margin: auto;
        width: 100%;
    }

    .card.login-form .card-body {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    footer .footer-info>.row>[class*=col-] {
        padding: 0.75rem 2rem;
    }

    .collapse-trigger {
        display: block;
        float: right;
    }
}

@media screen and (min-width: 576px) {
    .inline-button {
        gap: 4px;
    }
}

@media screen and (max-width: 576px) {
    .qrcode {
        max-width: 80%;
        height: auto;
    }

    .cardpaymentotp .inline-button {
        flex-direction: initial;
    }

    .container.metode-pembayaran.konfirmasi {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .inline-button {
        flex-direction: column;
        gap: 0.5rem;
    }

    .konfirmasi-detail {
        max-height: unset;
    }

    .cardpaymentotp {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        padding-left: 0;
        border: none !important;
    }

    .cardpaymentotp {
        font-size: 12px;
    }

    .cardpaymentotp .btn {
        font-size: 12px;
    }

    .detail-pembayaran-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .detail-pembayaran {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    /* #dataPembayaran.data-pembayaran.collapse-animate,
    #dataPembayaran2.data-pembayaran.collapse-animate {
        height: fit-content;
    } */
    .nav-page {
        left: 1.375rem;
    }

    .nav-page.logout {
        right: 1.375rem;
    }

    .nav-page.logout span:not(.ic-out) {
        display: none;
    }

    .nav-page .nav-mobile {
        display: block;
    }

    .nav-page:not(.logout) span:not(.nav-mobile) {
        display: none;
    }

    .card.login-form .card-body {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .login-form {
        margin: auto;
        width: 100%;
        border: none;
    }
}

@media screen and (max-width: 320px) {

    html,
    body {
        font-size: 14px;
    }

    .custom-radiobutton .form-check-inline label {
        width: 120px;
    }

    input#cvv {
        width: 98px;
    }

    #dataPembayaran.data-pembayaran.collapse-animate,
    #dataPembayaran2.data-pembayaran.collapse-animate {
        height: fit-content;
    }

    .cardpaymentotp {
        font-size: 11px;
    }

    .cardpaymentotp .btn {
        font-size: 10px;
    }

    .infocheckout .infocheckout-row>span>input {
        margin-top: 1px;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
}

@media only screen and (min-width: 320px) and (max-width:360px) {
    .grey-box {
        margin-bottom: 3.5rem !important;
    }
}

@media only screen and (min-width: 361px) {
    .grey-box {
        margin-bottom: 8rem !important;
    }
}

@media only screen and (min-width:420px) {
    .grey-box {
        margin-bottom: 5rem !important;
    }
}
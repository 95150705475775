.btn-primary-regis {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: rgb(15, 10, 88);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(15, 10, 88);
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
  color: rgb(15, 10, 88);
}

.same-size {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

span.rp {
  display: inline-block;
  position: relative;
}

span.rp::before {
  content: "Rp ";
  font-family: "Roboto Regular", sans-serif;
  font-size: 1.5em;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
}

/* body { */
/* margin: 0; */
/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", */
/* "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", */
/* sans-serif; */
/* -webkit-font-smoothing: antialiased; */
/* -moz-osx-font-smoothing: grayscale; */
/* background-color: #f7f7f7; */
/* background-color: cyan; */
/* overflow-x: hidden; */
/* width: 100%; */
/* } */

.header-image-dashboard {
  min-height: 170px;
  background: linear-gradient(
    to right,
    #611db7 0,
    #45429f 40%,
    #4f1698 60%,
    #362993 90%,
    #362993 100%
  );
}

.dashboard-left {
  min-height: 350px;
  width: 250px;
  background-color: #ffffff;
  margin-top: -55px;
  /* margin-bottom: 100px; */
  margin-left: 20px;
  /* margin-right: 100px; */
  position: absolute;
  border-radius: 25px;
  /* padding: 20px 10px 10px 20px; */
  /* display: none; */
}

.dashboard-left h2 {
  margin-left: 30px;
  margin-top: 30px;
  color: #362994;
}

.dashboard-left h1 {
  margin-left: 30px;
  margin-right: 30px;
  color: #362994;
  font-size: 20px;
  font-weight: bold;
  margin-top: -10px;
}

.text {
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 30px;
  color: #adadad;
  font-weight: 600;
  font-size: 12px;
}

.dashboard-right {
  min-height: 350px;
  width: 800px;
  background-color: #ffffff;
  margin-top: -55px;
  /* margin-bottom: 100px; */
  margin-left: 290px;
  /* margin-right: 100px; */
  position: absolute;
  border-radius: 25px;
  /* padding: 20px 10px 10px 20px; */
  /* display: none; */
}

.dashboard-table {
  min-height: 200px;
  min-width: 800px;
  background-color: #ffffff;
  /* background-color: #ffffff; */
  /* margin-top: 50px; */
  margin-bottom: 100px;
  /* margin-left: 290px; */
  /* margin-right: 100px; */
  /* position: absolute; */
  /* border-radius: 25px; */
  /* padding: 20px 10px 10px 20px; */
  display: none;
}

.dateForm input[type="text"] {
  padding-left: 20px;
  border-radius: 1px;
  height: 28px;
  font-size: 14px;
  font-family: "SansPro";
  size: 1;
}

.width-50 {
  width: 60% !important;
  /* height: 28px !important; */
  margin-left: 20px;
}

.width-150 {
  width: 135% !important;
  margin-left: 20px;
  margin-right: 20px !important;
  /* height: 28px !important; */
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 200px;
  padding-left: 7px;
  border-radius: 2px;
  /* height: 28px; */
  font-size: 14px;
  font-family: "SansPro";
}

@media only screen and (max-width: 780px) {
  .header-image-dashboard {
    height: 72px;
    background: linear-gradient(
      to right,
      #611db7 0,
      #45429f 40%,
      #4f1698 60%,
      #362993 90%,
      #362993 100%
    );
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
  }
}

#dropdown-basic.btn-primary {
  background: transparent;
  border: none;
}

.dashboard-admin input.form-control,
.dashboard-admin select.form-control {
  border-radius: 7px;
}

.btn-outline.cus-checkbox,
.btn-outline.cus-checkbox:hover {
  border: 1px solid #e3e3e3;
  color: #111b1d;
}

input:checked ~ .btn-outline.cus-checkbox {
  border: 1px solid #00008f;
  background-color: #fff;
}

.cus-checkbox {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  border-radius: 7px;
}

.cus-checkbox::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../../assets/new/img/icons/uncheckbox.svg);
  margin-right: 20px;
}

input:checked ~ .cus-checkbox::before {
  background: url(../../assets/new/img/icons/checkbox.svg);
}

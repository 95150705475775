html,
body {
    color: #343C3D;
}

::-moz-selection {
    /* Code for Firefox */
    color: #ffffff;
    background: #f07662;
}

::selection {
    color: #ffffff;
    background: #f07662;
}

a[type=button] {
    text-decoration: none;
}

a:not([type=button]) {
    font-weight: 700;
    color: #00008F;
    text-decoration: none;
}

a[aria-disabled=true] {
    font-weight: 400;
    color: #999999;
    cursor: default;
}

.modal-sm {
    --bs-modal-width: 380px !important;
}

.modal-footer {
    border-top: none;
}

label {
    font-size: 0.9375rem;
}

input.form-control,
.form-select {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
}

input.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999999;
    opacity: 1;
    /* Firefox */
}

input.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999999;
}

input.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #999999;
}

.card-input {
    display: flex;
}

.card-input input.form-control {
    width: 57px;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

input#cvv {
    width: 124px;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.text-grey {
    color: #5F5F5F;
}

.ribbon {
    background-color: #4976BA;
}

.sub-ribbon {
    background-color: #F8FAFB;
}

.btn {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-weight: 600;
}

.btn-primary,
.btn.btn-primary:visited {
    background-color: #00008F;
    border-color: #00008F;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:focus-visible {
    background-color: #00006e;
    border-color: #00006e;
}

.btn-outline-primary,
.btn.btn-outline-primary:visited {
    background-color: #ffffff;
    border-color: #00008F;
    color: #00008F;
}

.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:active,
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:focus-visible {
    background-color: #f3f3ff;
    border-color: #00006e;
    color: #00008F;
}

.btn-outline-secondary,
.btn.btn-outline-secondary:visited {
    background-color: #ffffff;
    border-color: #cccccc;
    color: #999999;
}

.btn.btn-outline-secondary:hover,
.btn.btn-outline-secondary:active,
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary:focus-visible {
    background-color: #ffffff;
    border-color: #cccccc;
    color: #999999;
}

.btn-outline-danger,
.btn.btn-outline-danger:visited {
    background-color: #ffffff;
    border-color: #C91432;
    color: #C91432;
}

.btn.btn-outline-danger:hover,
.btn.btn-outline-danger:active,
.btn.btn-outline-danger:focus,
.btn.btn-outline-danger:focus-visible {
    background-color: #ffeef1;
    border-color: #C91432;
    color: #C91432;
}

.btn:first-child:active,
.btn:active {
    transform: scaleX(0.95);
}

.highlight-text-plain {
    font-weight: 700;
    color: #00008F;
}

.highlight-text-red-plain {
    font-weight: 700;
    color: #C91432;
}

.highlight-text-md-plain {
    font-weight: 600;
    color: #00008F;
}

.highlight-text-md-red-plain {
    font-weight: 600;
    color: #C91432;
}

.highlight-text {
    font-weight: 700;
    color: #00008F;
    font-size: larger;
}

.highlight-text-md {
    font-weight: 600;
    color: #00008F;
    font-size: larger;
}

.highlight-text-red {
    font-weight: 700;
    color: #C91432;
    font-size: larger;
}

.highlight-text-red-md {
    font-weight: 600;
    color: #C91432;
    font-size: larger;
}

.color-main {
    color: #00008F;
}

.color-dark {
    color: #111B1D;
}

.card.login-form .card-body {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.card-header {
    background-color: #fff;
}

.card-text-label {
    font-size: 0.9375rem;
    color: #5F5F5F;
    margin-bottom: 0;
}

.card-text-data {
    font-size: 0.9375rem;
    color: #111B1D;
    margin-bottom: 0.5rem;
}

.container.index {
    max-width: 360px;
}

.container.pembayaran-kartu {
    max-width: 490px;
}

.container.metode-pembayaran {
    max-width: 460px;
}

.container.pembayaran {
    max-width: 605px;
}

.warning-box {
    background-color: #FAE1E5;
    border-radius: 0.485rem;
    padding: 0.625rem 1rem;
    text-align: start;
    margin-bottom: 2rem;
}

.warning-box>p {
    margin: 0;
}

.grey-box {
    background-color: #F3F7F9;
    border-radius: 0.485rem;
    padding: 0.75rem 1rem;
    text-align: start;
    margin-bottom: 5.5rem;
}

.ketentuan-pembayaran ol {
    padding-left: 1.25rem;
}

.ketentuan-pembayaran ol li {
    padding-left: 0.2rem;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.nav.nav-pills {
    border: 1px solid #E3E3E3;
    border-radius: 0.5rem;
    background-color: #ffffff;
    position: relative;
    z-index: 1;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #00008F;
}

.nav-link {
    color: #343C3D;
    font-weight: 600;
}

.nav-link:focus,
.nav-link:hover {
    color: #00008F;
}

.nav-pills+.card {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1rem;
    padding-top: 1rem;
    border: 1px solid #E3E3E3;
}

.form-check-input:checked {
    background-color: #00008F;
    border-color: #00008F;
}

footer .footer-info {
    background-color: #F8FAFB;
    display: flex;
}

footer .footer-info>.row {
    max-width: 1200px;
    margin: auto;
    width: 100%;
}

footer .footer-info>.row>[class*=col-] {
    padding: 3rem;
}

footer .footer-text-icon {
    display: flex;
    flex-direction: row;
}

footer .footer-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

footer .socmed-inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

footer .socmed-inline>a {
    width: fit-content;
}

footer .socmed-inline>a>* {
    margin-top: 4px;
    margin-right: 12px;
    margin-bottom: 1rem;
}

footer .footer-legal {
    padding: 1rem 2rem;
    text-align: center;
    color: #1a1919;
    background-color: #ffffff;
}

.no-drag {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    /* user-drag: none; */
}

.login-page {
    height: 90vh;
    min-height: 400px;
}

.login-page .row {
    margin: 0;
}

.login-logo {
    margin-bottom: 3rem;
}

.login-form {
    margin: auto auto auto 0;
    width: 85%;
    max-width: 406px;
}

.input-group-custom {
    position: relative;
    margin-bottom: 5px;
    margin-top: 1rem;
}

.input-group-custom .input-group-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.input-group-custom .input-group-icon-right {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.input-group-custom .form-control {
    padding: 0.75rem 2rem 0.75rem 3.2rem;
}

.detail-pembayaran .input-group-custom {
    position: relative;
    margin-bottom: 0.75rem;
    margin-top: 0;
}

.detail-pembayaran .form-control {
    padding: 0.75rem 1rem;
    font-size: inherit;
}

.form-tip {
    text-align: left;
    color: #999999;
    font-size: 0.875rem;
    margin: 0 0 0 5px;
}

.form-tip.form-alert {
    color: #C91432;
}

.captcha {
    padding: 0.75rem 0;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background-color: #E5E5E5;
    color: #999999;
    border-color: #E5E5E5;
}

button[type=submit] {
    font-weight: 600;
}

.collapse-trigger {
    display: none;
}

.flip {
    transform: rotate(180deg);
}

.nav-page {
    position: absolute;
    display: flex;
    /* margin-left: 5rem; */
    left: 6%;
    text-decoration: none;
    color: #ffffff;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
}

.nav-page:active {
    transform: translateX(-2px);
}

.nav-page [class*=ic-] {
    margin-right: 0.3rem;
}

.nav-page.logout {
    right: 6%;
    left: unset;
}

.nav-page:active.logout {
    transform: translateX(2px);
}

.nav-page .nav-mobile {
    display: none;
}

.nav-page > button,
.nav-page > button:hover,
.nav-page > button:active,
.nav-page > button:focus,
.nav-page > button:hover {
    background-color: transparent !important;
    outline: none;
    border: none;
}

.subtitle-page {
    font-size: 1.125rem;
}

input[type=radio]+label {
    cursor: pointer;
}

/* .h-0 {
    max-height: 0 !important;
    overflow-y: hidden !important;
    transition: max-height 2s ease-in-out;
} */

.data-pembayaran {
    max-height: 0 !important;
    padding-top: 0;
    padding-bottom: 0;
    transition: max-height 0.5s ease-out !important;
}

*[class*=collapse-animate-] {
    transition: max-height 0.5s ease-in !important;
}

*.collapse-animate-xl {
    max-height: 700px !important;
}

*.collapse-animate-lg {
    max-height: 500px !important;
}

*.collapse-animate-md {
    max-height: 200px !important;
}

*.collapse-animate-sm {
    max-height: 80px !important;
}

.v-hidden {
    overflow-y: hidden;
}

.tab-pane>.row {
    position: relative;
}

.form-check>.form-check-input:not(checked)[type=radio]~.data-pembayaran {
    display: none;
}

/* #dataPembayaran.data-pembayaran.collapse-animate {
    animation: kreditExpand 0.8s ease-out;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: fit-content;
} */

/* #dataPembayaran2.data-pembayaran.collapse-animate {
    animation: gopayExpand 0.8s ease-out;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: fit-content;
} */

#beneficialDownload {
    max-height: 0 !important;
    transition: max-height 0.5s ease-out !important;
}

#beneficialDownload.collapse-animate {
    max-height: 500px !important;
    transition: max-height 0.8s ease-out !important;
}

#registrasiKredit.collapse-animate {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* @keyframes beneficialExpand {
    0% {
        height: 0;
        overflow-y: hidden;
    }
    100% {
        height: 114px;
        overflow-y: unset;
    }
}

@keyframes kreditExpand {
    from {
        height: 0;
        overflow-y: hidden;
    }
    to {
        height: 816px;
        overflow-y: unset;
    }
}

@keyframes gopayExpand {
    from {
        height: 0;
        overflow-y: hidden;
    }
    to {
        height: 696px;
        overflow-y: unset;
    }
}

@keyframes registrasikartuExpand {
    from {
        height: 0;
        overflow-y: hidden;
    }
    to {
        height: 1070px;
        overflow-y: unset;
    }
} */

.countdown {
    color: #C91432;
}

.detail-pembayaran-wrapper {
    display: flex;
    flex-direction: row;
}

.detail-pembayaran {
    padding-left: 1.5rem;
}

.detail-pembayaran ul {
    font-size: 0.875rem;
}

.detail-pembayaran ul li p {
    margin-bottom: 0.75rem;
}

.custom-radiobutton .form-check-inline {
    position: relative;
    padding: 0;
    margin: 0;
}

.custom-radiobutton .form-check-inline input {
    position: absolute;
    visibility: hidden;
}

.custom-radiobutton .form-check-inline label {
    width: 160px;
    height: 48px;
    display: grid;
    place-content: center;
    border: 1px solid #E3E3E3;
    font-weight: 600;
}

.custom-radiobutton .form-check-inline:first-of-type label {
    border-radius: 0.5rem 0 0 0.5rem;
}

.custom-radiobutton .form-check-inline:last-of-type label {
    border-radius: 0 0.5rem 0.5rem 0;
}

.custom-radiobutton .form-check-inline input:checked+label {
    background-color: #00008F;
    border: 1px solid #00008F;
    color: #ffffff;
}

.custom-radiobutton .form-check-inline input:checked+label:hover {
    background-color: #00006e;
    border: 1px solid #00006e;
}

.custom-radiobutton .form-check-inline input:not(:checked)+label:hover {
    color: #00008F;
    box-shadow: 0px 1px 6px rgba(30, 30, 197, 0.2);
}

.card-header.accordion-item {
    background-color: transparent;
}

.card-body.accordion-collapse {
    border-top: 1px solid rgba(0, 0, 0, 0.175);
}

.logo-inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cardpaymentotp {
    text-align: start;
}

.cardpaymentotp .highlight-text-red-plain {
    color: red;
    font-size: 1.2rem;
    font-weight: 900;
    line-height: 2rem;
}

.infocheckout {
    margin-bottom: 0.5rem;
}

.infocheckout ul {
    padding: 0;
}

.infocheckout .infocheckout-row {
    display: flex;
    flex-direction: row;
    list-style: none;
    width: 100%;
    margin-bottom: 0.5rem;
}

.infocheckout .infocheckout-row>* {
    width: 50%;
}

.infocheckout .infocheckout-row:last-of-type {
    align-items: center;
}

.infocheckout .infocheckout-row>span:first-of-type {
    font-weight: 600;
    position: relative;
}

.infocheckout .infocheckout-row>span:first-of-type::after {
    content: ":";
    margin-right: 0.25rem;
    right: 0;
    position: absolute;
}

.infocheckout .infocheckout-row>span>input {
    width: 60%;
    margin-left: 3px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.inline-button {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
}

.cardpaymentotp {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 3rem;
    padding-left: 3rem;
}

.cardpaymentotp .btn {
    border-radius: 0;
    font-size: 14px;
}

.cardpaymentotp .btn.btn-primary {
    background: linear-gradient(180deg, #196CB2 0%, #043F8B 100%);
    border: 1px solid #1F6CA8;
    padding: 0.375rem;
    min-width: 100px
}

.cardpaymentotp .btn.btn-secondary {
    background: #ffffff;
    border: 1px solid #1F6CA8;
    color: #1F6CA8;
    padding: 0.375rem;
}

.cardpaymentotp .btn.btn-secondary:hover {
    background: #f7fbff;
}

.konfirmasi-detail {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: calc((54px + 8px) * 4);
}

select:invalid {
    color: #999999 !important;
}

select:invalid option {
    color: #343C3D !important;
}

.modal-content.tnc .modal-header {
    text-align: center;
    border-bottom: 0;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    padding-top: 1.5rem;
}

.modal-content.tnc .modal-title {
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
}

.modal-content.tnc .modal-body {
    text-align: justify;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
    max-height: 60vh;
    overflow-y: auto;
}

.modal-content.tnc .modal-body::-webkit-scrollbar {
    width: 0.1rem;
}

.modal-content.tnc .modal-body::-webkit-scrollbar-track {
    background-color: transparent;
}

.modal-content.tnc .modal-body::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 8px;
}

.redirect-timer {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 8rem;
    aspect-ratio: 1;
    margin: auto auto 1rem auto;
    color: #5F5F5F;
}

.redirect-timer::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 8rem;
    aspect-ratio: 1;
    border: 2px dashed #CCCCCC;
    border-radius: 8rem;
    animation: rotateSlow 15s linear infinite;
    pointer-events: none;
}

@keyframes rotateSlow {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.redirect-timer>span:first-of-type {
    font-weight: 600;
    font-size: 2rem;
}

.redirect-timer>span:last-of-type {
    font-weight: 600;
    font-size: 1.125rem;
}

.container.otp {
    max-width: 680px;
}

.otp-timer {
    font-size: 1.25rem;
    font-weight: 700;
    color: #4976BA;
}

/* OTP Start */
.digit-group input {
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    width: 2.8125rem;
    height: 3.625rem;
    background-color: #fff;
    border: 1px solid #CCCCCC;
    border-radius: 0.625rem;
    line-height: 50px;
    text-align: center;
    margin: 0 2px;
}

.digit-group .splitter {
    padding: 0 5px;
    color: white;
    font-size: 24px;
}

.prompt {
    margin-bottom: 20px;
    font-size: 20px;
    color: white;
}

.digit-group input:focus-visible {
    outline: 1px solid #043F8B;
}

/* OTP End */

.warning-wrapper {
    margin-bottom: 3rem;
}

.warning-box {
    background-color: #FAE1E5;
    border-radius: 0.485rem;
    padding: 0.625rem 1rem;
    text-align: start;
    margin-bottom: 2rem;
}

.warning-box p {
    margin: 0;
}

.warning-wrapper .warning-box {
    max-width: 512px;
    font-size: 0.9375rem;
    margin-bottom: 1rem;
}

.warning-box.warning-error {
    border: 1px solid #C91432;
}

.warning-box.warning-info {
    background-color: #ffbc110a;
    border: 1px solid #FFBC11;
}

.container .warning-box.warning-error:last-child {
    margin-bottom: 2rem;
}

.logo-link {
    border: none;
    outline: none;
    background-color: transparent;
}

.collapse-animate-lg {display:inline-block !important;}

.collapse-animate-md {display:inline-block !important;}
.container-popup-email{
    max-width: 300px;
    text-align: center;
    background-color: #FFFFFF;
    margin-left: calc((100% - 300px)/2);
    padding: 30px;
    z-index: 1002;
    border-radius: 2px;
    margin-top: 70px;
    font-size: 11px;
}
.container-popup-email img{
    margin-bottom: 20px;
}
.container-popup-email .desc{
    margin-bottom: 20px;
}